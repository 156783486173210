import { Effect } from "../../Materials/effect";

let name = 'bumpVertex';
let shader = `#if defined(BUMP) || defined(PARALLAX) || defined(CLEARCOAT_BUMP)
#if defined(TANGENT) && defined(NORMAL)
vec3 tbnNormal=normalize(normalUpdated);
vec3 tbnTangent=normalize(tangentUpdated.xyz);
vec3 tbnBitangent=cross(tbnNormal,tbnTangent)*tangentUpdated.w;
vTBN=mat3(finalWorld)*mat3(tbnTangent,tbnBitangent,tbnNormal);
#endif
#endif`;

Effect.IncludesShadersStore[name] = shader;
/** @hidden */
export var bumpVertex = { name, shader };
